<template>
  <div v-if="show" class="offline-container">
    <img src="./../../src/assets/illustrations/offline_illustration.png" alt="Your'e offline dude">
    <v-card class="offline-card">
      <div class="offline-title">
        Lost Connection
      </div>
      <div class="offline-desc">
        Whoops.. no internet connection found. Check your connection.
      </div>
      <v-btn class="btn-offline-refresh" color="primary" :loading="isRefreshLoading" @click.prevent="refreshConnection()">
        Try Again
      </v-btn>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isRefreshLoading: false
  }),
  methods: {
    refreshConnection () {
      this.isRefreshLoading = true
      setTimeout(() => {
        this.isRefreshLoading = false
      }, 3000)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../src/assets/scss/apps';

.offline-container{
  background-color: $primary;
  position: fixed;
  left: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
  max-width: 500px;
  height: 100%;
  z-index: 205;
  img{
    position: absolute;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
    width: calc(200px + .4vw);
    height: calc(200px + .4vw);
  }
  .offline-card{
    background-color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    border-radius: 50px 50px 0 0;
    min-height: 340px;
    padding: 50px 30px;
    .offline-title{
      @include font-size(28px);
      font-weight: 700;
      margin-bottom: 44px;
    }
    .offline-desc{
      @include font-size(16px);
    }
    .btn-offline-refresh{
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 50px;
      display: block;
      border-radius: 50px;
      height: 46px;
      .v-btn__content{
        @include font-size(14px);
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }
}
</style>
